<template>
	<v-container>
		<v-timeline
		align-top
		>
			<v-timeline-item
				v-for="(item, i) in items"
				:key="i"
				:color="item.color"
				:icon="item.icon"
				fill-dot
			><span slot="opposite"><strong>{{item.date}}</strong></span>
			<v-card
				:color="item.color"
				dark
			>
				<v-card-title class="title" style="word-break:normal">
					{{item.title}}
				</v-card-title>
				<v-card-text class="white text--primary" v-if="item.text">
					<p>{{item.text}}</p>
				</v-card-text>
			</v-card>
			</v-timeline-item>
		</v-timeline>
	</v-container>
</template>
<script>
	let icons = {
		DONE:'mdi-check-bold',
		DOING:'mdi-clock-check',
		PLANNING:'mdi-brain',
		FUTURE:'mdi-beaker-question'
	}
export default {
    metaInfo: {
        // override the parent template and just use the above title only
        title: 'Roadmap',
        // all titles will be injected into this template
        titleTemplate: '%s | Brukmat'
    },
	data: () => ({
		items: [
			{
				color: 'green',
				icon: icons.DONE,
				title:"Importere ingredienser og næringsinnhold",
				date:"Mai, 2021"
			},
			{
				color: 'green',
				icon: icons.DONE,
				title:"Lage oversikt over næringsinnhold",
				date:"Mai, 2021"
			},
			{
				color: 'green',
				icon: icons.DONE,
				title:"Søkbare oppskrifter ut i fra ingredienser",
				date:"Mai, 2021"
			},
			{
				color: 'green',
				icon: icons.DONE,
				title:"Mulighet for å lage oppskrifter med framgangsmåter og ingredienser",
				date:"Mai, 2021"
			},
			{
				color: 'green',
				icon: icons.DONE,
				title:"Legge inn basis framgangsmåter på oppskrifter",
				date:"Mai, 2021"
			},
			{
				color: 'green',
				icon: icons.DONE,
				title:"Oversiktside for hver enkelte oppskrift",
				date:"Mai, 2021"
			},
			{
				color: 'green',
				icon: icons.DONE,
				title:"Oversiktside for hver enkelte ingrediens",
				date:"Mai, 2021"
			},
			{
				color: 'green',
				icon: icons.DONE,
				title:"Side for å kalkulere næringsinnhold fra flere ingredienser",
				date:"Mai-Juni, 2021"
			},
			{
				color: 'blue lighten-1',
				icon: icons.DOING,
				title:"Legge inn mindre mengde med oppskrifter",
				date:"Mai-Juni, 2021"
			},
			{
				color: 'blue lighten-1',
				icon: icons.DOING,
				title:"Søking av ingredienser ved bruk av næringsinnhold",
				date:"Mai-Juni, 2021"
			},
			{
				color: 'purple darken-1',
				icon: icons.PLANNING,
				title:"Generere/Lage oppskrifter",
				date:"Sommer, 2021"
			},
			{
				color: 'purple darken-1',
				icon: icons.PLANNING,
				title:"Generere næringsinnhold på oppskrifter",
				date:"Sommer, 2021"
			},
			{
				color: 'red lighten-2',
				icon: icons.FUTURE,
				title:"'3D' framgangsmåter på oppskrifter",
				date:"Høst, 2021"
			},
		],
	}),
}
</script>